import { Injectable, inject } from '@angular/core';
import { Observable, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { ParticipationModelDto } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class ModelService {
	private readonly api = inject(ApiObservableService);

	getApp(appId: string): Observable<ParticipationModelDto[]> {
		return this.api
			.get<ParticipationModelDto[]>(`participate/model/portal/${appId}`)
			.pipe(take(1));
	}
}
