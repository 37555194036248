import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface AnswerForm {
	reaction: FormControl<string>;
	answer: FormControl<string>;
}

@Injectable({
	providedIn: 'root'
})
export class ParticipationItemService {
	form = new FormGroup<AnswerForm>({
		reaction: new FormControl({ value: '', disabled: false }, { nonNullable: true }),
		answer: new FormControl({ value: '', disabled: false }, { nonNullable: true })
	});

	patchAnswer(answer: string): void {
		this.form.patchValue({ answer });
	}

	patchReaction(reaction: string): void {
		this.form.patchValue({ reaction });
	}
}
